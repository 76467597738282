import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { BeatLoader } from 'react-spinners'
import './WaitingModal.css'

const WaitingModal = (props) => {
    const { isOpen, header = 'Loading' } = props
    return (
        <Modal className="waiting-modal--centered animated fadeIn" isOpen={isOpen}>
            <ModalHeader className="waiting-modal--centered">{header}</ModalHeader>
            <ModalBody className="waiting-modal--centered">
                <div className="waiting-modal__spinner">
                    <BeatLoader color="currentColor" />
                </div>
            </ModalBody>
        </Modal>
    )
}

export default WaitingModal;
