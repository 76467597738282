import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import appStorage from '../services/appstorage'

const PrivateRoute = ({ component: Component, ...rest }) => {
  let token = appStorage.getToken();
  return (
    <Route {...rest} render={(props) => (token? <Component {...props} />: <Redirect to='/login' /> )} />
  );
}

export default PrivateRoute;
