import React from 'react';
import {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import './ConfirmationModal.css'

const ConfirmationModal = (props) => {
    const { isOpen, header, body, button1, button2, button1Text, button2Text } = props
    return (
        <Modal className="confirmation-modal animated fadeIn"  isOpen={isOpen} >
            <ModalHeader className="confirmation-modal-header" >
                <FontAwesomeIcon className="fa-1x" icon={faCheckCircle} /> {header}</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>
               {button1 ? <Button color="primary" onClick={button1}>{button1Text ? button1Text : 'OK'}</Button> : null} 
               {button2 ? <Button color="secondary" onClick={() => button2()}>{button2Text ? button2Text : 'Cancel'}</Button> : null} 
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal