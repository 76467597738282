import React from 'react'
import Footer from '../footer/Footer';
import {
    Container, Navbar,
    NavbarBrand,
    Nav,
    NavItem
} from 'reactstrap'

const Unauthorized = (props) => {
    return (
        <div id="wrap">
            <Navbar dark expand="md" className="header">
                <Nav className="container-fluid" navbar>
                    <NavItem>
                        <NavbarBrand href="/">Recording As A Service</NavbarBrand>
                    </NavItem>
                </Nav>
            </Navbar>
            <div id="main">
                <Container>
                    You're not authorized to access the requested resource, please check with your system administrator.
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Unauthorized