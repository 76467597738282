const defaultState = { isLoggedIn: false }

const sessionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_SESSION':
            return { ...action.session, isLoggedIn: true }
        case 'CLEAR_SESSION':
            return defaultState
        default:
            return state
    }
}

export default sessionReducer
