import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import appStorage from '../../services/appstorage'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft , faHourglass} from '@fortawesome/free-solid-svg-icons'
import RecordingPlaybackModal from '../Modals/recordingPlaybackModal/RecordingPlaybackModal'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import utils from '../../services/utils'
import moment from 'moment'

import './ConversationDetails.css'
import "react-table-6/react-table.css";

const ConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
    const [isStillProcessing, setIsStillProcessing] = useState(true);
    const [recordings, setRecordings] = useState([])
    const [conversation, setConversation] = useState()
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => { // init load
        setRole(appStorage.getItem(constants.R2S_USER_ROLE));
        console.log(`ConversationDetails.js:::useEffect:::Conversation Details role is ${role} `);
        const fetchConversation = async () => {
            let convMetaDataresponse;
            let processedRecordings = [];
            try {
                setIsWaitingModalOpen(true);
                
                let usertoken = appStorage.getToken();
                console.log(`userToken is ${usertoken}`);
                let convResponse = await backend.getConversation(usertoken, id)
                console.log('getConversation.response:', convResponse)
                setConversation(convResponse)
                if (convResponse.recordings) {
                    convMetaDataresponse = await backend.getConversationMetadata(usertoken, id, convResponse.recordings)
                    console.log(`convMetaDataresponse is ${JSON.stringify(convMetaDataresponse)}`);
                    setRecordings(convMetaDataresponse.recordings)
                    console.log(`setRecordings completed`);
                }   
            } catch (error) {
                console.log('fetchConversation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }
            for(let recording of convMetaDataresponse.recordings){
                console.log(`fetchProcessedRecordings:: recordings are ${JSON.stringify(recording)}`);
                console.log(`fetchProcessedRecordings:: status is ${recording.status}`);
                if(recording.status!= 'COMPLETED'){
                    setIsStillProcessing(true);
                    console.log(`fetchProcessedRecordings:: Recording Response details ${JSON.stringify(recording)}`);
                    let apiCallSuccess = false
                    do{
                        let usertoken = appStorage.getToken();
                        let s3path = `${recording.relative_path}.opus`;
                        console.log(`fetchProcessedRecordings:: s3path is ${s3path} and it is type of ${typeof s3path}`);
                        console.log(`fetchProcessedRecordings:: We are about to invoke API call to retrive processed Recordings with relative path as ${s3path}`);
                        try {
                            let apiResponse = await backend.getRecordingURLStatus(usertoken,s3path);
                            console.log(`fetchProcessedRecordings:: API response for recording URL Status is ${JSON.stringify(apiResponse)}`);   
                            if(apiResponse.convertionStatus && apiResponse.convertionStatus == 'COMPLETED'){
                                apiCallSuccess = true; 
                                recording.status = apiResponse.convertionStatus;
                                recording.url = apiResponse.recURL;
                            }else{
                                apiCallSuccess = false; 
                            }
                            
                        } catch (exception) {
                            console.log(`fetchProcessedRecordings::Got Exception in get recording URL Status`);
                            console.log(exception);
                            apiCallSuccess = false;
                        }
                    
                        if(apiCallSuccess== false){
                            console.log(`fetchProcessedRecordings:: will check recordings status after 10 seconds ${new Date()}`);
                            await sleepFor(10*1000);
                            console.log(`fetchProcessedRecordings:: will retry now ${new Date()}`);
                            
                        }
                    }while(apiCallSuccess == false);
                    
                    console.log(`Processed Recordings are ${JSON.stringify(recording)}`);
                    processedRecordings.push(recording);
                    console.log(`Processed Recordings are ${JSON.stringify(processedRecordings)}`);
                    if(processedRecordings && processedRecordings.length>0){
                        setRecordings(processedRecordings);
                        setIsStillProcessing(false);
                    }else{
                        console.log(`We dont have any processed recordings`);
                    }
                    
                }else{
                    console.log(`recording.status is COMPLETED`);
                }
            }
        }
        fetchConversation();

        

    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('Conversation details :::> handleRecordingClick:', recording);
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        if (!conversation) return

        
        /**
         * console.log(`Conversation details are ${JSON.stringify(conversation)}`);
         * console.log(`Conversation recordings are ${JSON.stringify(conversation.recordings)}`);
         * console.log(`Conversation recordings length is ${JSON.stringify(conversation.recordings.length)}`);
         * console.log(`Conversation recordings first element is are ${JSON.stringify(conversation.recordings[0])}`);
        */
        
        let media_type = 'Call';
        if(conversation.recordings && conversation.recordings.length>0){
            if(conversation.recordings[0]&& conversation.recordings[0].media_type){
                media_type = conversation.recordings[0].media_type;
                console.log(`Rendering Recording with Media type as ${media_type}`);
            }
        }


        
        switch (media_type) {
            default: // voice
                return <Fragment>
                    <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactTable
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={10}
                                minRows={0}
                                className="-highlight -striped table-container"
                                data={recordings}
                                columns={[ 
                                {
                                    Header: 'SID',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        return (
                                            <div className="overflow div-center">
                                                {row.original.sid}
                                            </div>
                                        )
                                    }
                                }, {
                                    Header: 'Play',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        
                                        
                                        if(isStillProcessing){
                                            console.log(`isStillProcessing :: is true`);
                                            //console.log(`isStillProcessing :: Row Original is ${JSON.stringify(row.original)}`);
                                            return (
                                                <Fragment>
                                                    <div className="div-center">
                                                        <button > Processing Play back<span/>
                                                            <FontAwesomeIcon icon={faHourglass} spin/>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        }else{
                                                return (
                                                    <Fragment>
                                                        <div className="div-center">
                                                            <button onClick={() => { handleRecordingClick(row.original) }}>
                                                                <FontAwesomeIcon icon={faPlay} />
                                                            </button>
                                                        </div>
                                                    </Fragment>
                                                )
                                        }

                                        }
                                    }
                                
                                ]}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer'
                                        }
                                    }
                                }}
                                getTrProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                getTrGroupProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                defaultFilterMethod={(filter, row) => {
                                    const id = filter.pivotId || filter.id;
                                    return (row[id] !== undefined ?
                                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                        : true
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                </Fragment>
        }
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <RecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                />

                <div className="px-2">
                    <Row>
                        <Col>

                            <button onClick={() => { history.push('/conversations') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details for SID- {conversation && conversation.sid}</span>

                            <hr />
                        </Col>
                    </Row>
                    
                    <Row xs="3">
                        <Col>
                            <span className="details-header">Agent</span><span className="details-value">{conversation && conversation.name}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Agent PBX ID</span><span className="details-value">{conversation && conversation.agent_pbx_id}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Organization</span><span className="details-value">{conversation && conversation.organization}</span>
                        </Col>
                        
                    </Row>
                    

                    <Row xs="3">
                        <Col>
                            <span className="details-header">ANI</span><span className="details-value">{conversation && conversation.ani}</span>
                        </Col>
                        <Col>
                            <span className="details-header">DNIS</span><span className="details-value">{conversation && conversation.dnis}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Extension</span><span className="details-value">{conversation && conversation.extension}</span>
                        </Col>
                    </Row>
                    
                    
                    <Row xs="3">
                        <Col>
                            <span className="details-header">Division</span><span className="details-value">
                                {conversation&&conversation.division?conversation.division:"Not available"}
                            </span>
                        </Col> 
                        <Col>
                            <span className="details-header">Queue</span><span className="details-value">
                                {conversation&&conversation.queue_name?conversation.queue_name:"Not available"}
                            </span>
                        </Col>
                        <Col>
                            <span className="details-header">Total Duration</span><span className="details-value">
                                {conversation&&conversation.duration?utils.convertSecsToHHMMSS(conversation.duration):''}</span>
                        </Col>
                    </Row>
                    
                    <Row xs="3">
                        <Col>
                            <span className="details-header">Number of Holds</span><span className="details-value">{conversation && conversation.number_of_holds}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Total Hold time</span><span className="details-value">
                                {conversation&&conversation.total_hold_time?utils.convertSecsToHHMMSS(conversation.total_hold_time):''}
                            </span>
                        </Col>
                        <Col>
                            <span className="details-header">Wrapup time</span><span className="details-value">
                                {conversation&&conversation.wrap_up_time?utils.convertSecsToHHMMSS(conversation.wrap_up_time):'' } 
                            </span>
                        </Col>
                    </Row>
                    <Row xs="3">
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.direction}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation&&moment(conversation.local_start_time).format(constants.DISPLAY_CONV_DATE_FORMAT)}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation&&moment(conversation.local_end_time).format(constants.DISPLAY_CONV_DATE_FORMAT)}</span>
                        </Col>
                    </Row>
                    <Row xs="3">
                        <Col>
                            <span className="details-header">Switch Call ID</span><span className="details-value">{conversation && conversation.switch_call_id}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Contact ID</span><span className="details-value">{conversation && conversation.contact_id}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Original Contact ID</span><span className="details-value">{conversation && conversation.original_contact_id}</span>
                        </Col>
                    </Row>

                  
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}

const sleepFor = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default ConversationDetails