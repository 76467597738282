import constants from '../constants'
/** 
 * removeStorage: removes a key from localStorage and its sibling expiracy key
 * params:
 *     key <string>     : localStorage key to remove
 * returns:
 *      <boolean> : telling if operation succeeded
 */
const removeStorage=(name) => {
    try {
        localStorage.removeItem(name);
        localStorage.removeItem(name + '_expiresIn');
    } catch(e) {
        console.log('removeStorage: Error removing key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
        return false;
    }
    return true;
}

/**
 *  getLocalStorageItem: retrieves a key from localStorage previously set with setStorage().
 *     params:
 *            key <string> : localStorage key
 *     returns:
 *         <string> : value of localStorage key
 *          null : in case of expired key or failure
*/
const getLocalStorageItem=  (key) => {
        
    const now = Date.now();  //epoch time, lets deal only with integer
    // set expiration for storage
    let expiresIn = localStorage.getItem(key+'_expiresIn');
    if (expiresIn===undefined || expiresIn===null) { expiresIn = 0; }

    if (expiresIn < now) {// Expired
        removeStorage(key);
        return null;
    } else {
        try {
            let value = localStorage.getItem(key);
            return value;
        } catch(e) {
            console.log('getStorage: Error reading key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
            return null;
        }
    }
}



/**
 *  getStorage: retrieves a key from localStorage previously set with setStorage().
 *     params:
 *            key <string> : localStorage key
 *     returns:
 *         <string> : value of localStorage key
 *          null : in case of expired key or failure
*/
const getSessionStorageItem=  (key) => {
        
    return sessionStorage.getItem(key);
}

/**
 *  getStorage: retrieves a key from localStorage previously set with setStorage().
 *     params:
 *            key <string> : localStorage key
 *     returns:
 *         <string> : value of localStorage key
 *          null : in case of expired key or failure
*/
const getItem=  (key) => {
     
    return getSessionStorageItem(key);
}


/** 
 * setLocalStorageItem: writes a key into localStorage setting a expire time
 *    params:
 *      key <string>     : localStorage key
 *      value <string>   : localStorage value
 *      expires <number> : number of seconds from now to expire the key
 *     returns:
 *       <boolean> : telling if operation succeeded
 */
const setLocalStorageItem =  (key, value, expires = 60*60) => {
    if (expires===undefined || expires===null) {
        expires = (60*60);  // default: seconds for 1 hour
    } else {
        expires = Math.abs(expires); //make sure it's positive
    }

    let now = Date.now();  //millisecs since epoch time, lets deal only with integer
    let schedule = now + expires*1000; 
    try {
        localStorage.setItem(key, value);
        localStorage.setItem(key + '_expiresIn', schedule);
    } catch(e) {
        console.log('setStorage: Error setting key ['+ key + '] in localStorage: ' + JSON.stringify(e) );
        return false;
    }
    return true;
}

/** 
 * setSessionStorageItem: writes a key into localStorage setting a expire time
 *    params:
 *      key <string>     : localStorage key
 *      value <string>   : localStorage value
 *      expires <number> : number of seconds from now to expire the key
 *     returns:
 *       <boolean> : telling if operation succeeded
 */
const setSessionStorageItem =  (key, value, expires = 60*60) => {
    
        sessionStorage.setItem(key, value);
        
}

/** 
 * setStorage: writes a key into localStorage setting a expire time
 *    params:
 *      key <string>     : localStorage key
 *      value <string>   : localStorage value
 *      expires <number> : number of seconds from now to expire the key
 *     returns:
 *       <boolean> : telling if operation succeeded
 */
const setItem =  (key, value, expires = 60*60) => {
    setSessionStorageItem(key,value);
    return true;
}
const getToken = () => {
    let usertoken = getItem(constants.GC_TOKEN);
    return usertoken;
}

export default {
    setItem,
    getItem,
    getToken
}