import React from 'react'
import { Container } from 'reactstrap'
import Header from '../header/Header';

const Management = () => {
    return (
        <div>
            <Header />
            <Container>
                Management
    </Container>

        </div>)
}

export default Management