import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
import moment from 'moment'
import utils from '../../services/utils'
import appStorage from '../../services/appstorage'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './ConversationsSearchCriteria.css'
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const ConversationsSearchCriteria = (props) => {
    const { queues, role, divisions , agents,agentID,conv_direction} = props
    const [dnis, setDNIS] = useState()
    const [ani, setANI] = useState()
    const [conversationID, setConversationID] = useState()
    const [switchcallID, setSwitchCallID] = useState()
    const [contactID, setContactID] = useState()
    const [originalContactID, setOriginalContactID] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [dynamicProps,setDynamicProps] = useState();

    const [selectedDivisions, setSelectedDivisions] = useState([])
    const [selectedAgents, setSelectedAgents] = useState([])
    const [selectedAgentIDs, setSelectedAgentIDs] = useState([])
    const [selectedConvDirection, setSelectedConvDirection] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date
    const [reloaddateRange, setReloaddateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    function onSelectDate(dateArray, dateString) {
        console.log(`onSelectDate:::: dateArray is ${JSON.stringify(dateArray)}`);
        console.log(`onSelectDate:::: value is ${JSON.stringify(dateString)}`);
        console.log(`onSelectDate:::: type is ${typeof(dateString)}`);
        setdateRange(dateString);
        let tempStartDate = moment(dateArray[0]);
        let tempEndDate = moment(dateArray[1]);
        console.log(`onSelectDate:::: reload date is ${tempStartDate} and ${tempEndDate}`);
        setReloaddateRange(tempStartDate,tempEndDate);
        console.log(`onSelectDate:::: reloaddateRangeis ${reloaddateRange}`);
        let valueProps = {
            value:[moment(tempStartDate),moment(tempEndDate)]
        };
        setDynamicProps(valueProps);

    }

    // init from session storage
    useEffect(() => {
        try {
            console.log(`ConversationsSearchCriteria:::role are ${JSON.stringify(role)}`)
            console.log(`ConversationsSearchCriteria:::agents are ${JSON.stringify(agents)}`)
            console.log(`ConversationsSearchCriteria:::conv_direction are ${JSON.stringify(conv_direction)}`)
            appStorage.setItem(constants.R2S_CONVERSATIONS,'');
            const searchCriteriaTemp = JSON.parse(appStorage.getItem(constants.R2S_SEARCH_CRITERIA));
            console.log(`ConversationsSearchCriteria:::: got search criteria as ${JSON.stringify(searchCriteriaTemp)}`);
            if(searchCriteriaTemp){
                console.log(`ConversationsSearchCriteria::::loadolddate ::::Found Conversation from previous search`);
                try {
                    if(searchCriteriaTemp.startDateTime && searchCriteriaTemp.endDateTime){
                        console.log(`ConversationsSearchCriteria:::: got valid date to preload`);
                        setdateRange([moment(searchCriteriaTemp.startDateTime),moment(searchCriteriaTemp.endDateTime)]);
                        let valueProps = {
                            value:[moment(searchCriteriaTemp.startDateTime),moment(searchCriteriaTemp.endDateTime)]
                        };
                        setDynamicProps(valueProps);
                        console.log(`ConversationsSearchCriteria::::loadolddate got ${valueProps}`);
                    }else{
                        console.log(`ConversationsSearchCriteria::::loadolddate got invalid date to preload`);
                    }

                    if(searchCriteriaTemp.agent){
                        console.log(`Setting Agent as ${searchCriteriaTemp.agent.split(',')}`);
                        let tempCriteria ;
                        let selectedAgentTemp ;
                        if(searchCriteriaTemp.agent == '*'){
                            selectedAgentTemp = agents.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.agent.split(',');
                            selectedAgentTemp = agents.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedAgentTemp)}`);
                        setSelectedAgents(selectedAgentTemp);
                        //setEndDate(searchCriteriaTemp.endDateTime);
                    }

                    if(searchCriteriaTemp.agentID){
                        console.log(`Setting agentID as ${searchCriteriaTemp.agentID.split(',')}`);
                        let tempCriteria ;
                        let selectedAgentIDTemp ;
                        if(searchCriteriaTemp.agentID == '*'){
                            selectedAgentIDTemp = agentID.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.agentID.split(',');
                            selectedAgentIDTemp = agentID.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedAgentIDTemp)}`);
                        setSelectedAgentIDs(selectedAgentIDTemp);
                    }

                    if(searchCriteriaTemp.conv_direction){
                        console.log(`Setting Agent as ${searchCriteriaTemp.conv_direction}`);
                        if(searchCriteriaTemp.conv_direction == '*'){
                            setSelectedConvDirection(conv_direction.filter((x => x.value !== '*')));
                        }else{
                            setSelectedConvDirection(conv_direction.filter((x => x.value == searchCriteriaTemp.conv_direction)));
                        }
                    }

                    if(searchCriteriaTemp.queues){
                        console.log(`Setting Agent as ${searchCriteriaTemp.queues}`);
                        let tempCriteria ;
                        let selectedQueuesTemp ;
                        if(searchCriteriaTemp.queues == '*'){
                            selectedQueuesTemp = queues.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.queues.split(',');
                            selectedQueuesTemp = queues.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedQueuesTemp)}`);
                        setSelectedQueues(selectedQueuesTemp);
                    }

                    if(searchCriteriaTemp.division){
                        console.log(`Setting Agent as ${searchCriteriaTemp.division}`);
                        let tempCriteria ;
                        let selectedDivisionsTemp ;
                        if(searchCriteriaTemp.division == '*'){
                            selectedDivisionsTemp = divisions.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.division.split(',');
                            selectedDivisionsTemp = divisions.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedDivisionsTemp)}`);
                        setSelectedDivisions(selectedDivisionsTemp);
                    }


                    if(searchCriteriaTemp.ani){
                        setANI(searchCriteriaTemp.ani);
                    }

                    if(searchCriteriaTemp.dnis){
                        setDNIS(searchCriteriaTemp.dnis);
                    }
                    
                    if(searchCriteriaTemp.conversationID){
                        setConversationID(searchCriteriaTemp.conversationID);
                    }
                    if(searchCriteriaTemp.contactID){
                        setContactID(searchCriteriaTemp.contactID);
                    }
                    if(searchCriteriaTemp.switchcallID){
                        setSwitchCallID(searchCriteriaTemp.switchcallID);
                    }
                    if(searchCriteriaTemp.originalContactID){
                        setOriginalContactID(searchCriteriaTemp.originalContactID);
                    }
                } catch (error) {
                     console.log(error);
                }
            }
            if (!searchCriteriaTemp) return;

        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        console.log(`ConversationsSearchCriteria:search::Started`);
        try {
            setStartSearch(true)
            if (!dateRange[0] || !dateRange[1]) {
                console.log(`ConversationsSearchCriteria:search::date range is not a valid value`);    
                return
            }
            console.log(`ConversationsSearchCriteria:search::date range is valid`);
            const searchCriteria = new Map()
            if (dateRange[0]){
                let tempStartDate = moment(dateRange[0],constants.DISPLAY_DATE_FORMAT);           
                searchCriteria.set(constants.SEARCH_PARAM_STARTDT, tempStartDate.format(constants.DATE_ISO_FORMAT));
                console.log(`ConversationsSearchCriteria::: Start Date is  ${tempStartDate.format(constants.DATE_ISO_FORMAT)}`);
            } 

            if (dateRange[1]){
                let tempEndDate = moment(dateRange[1],constants.DISPLAY_DATE_FORMAT);
                searchCriteria.set(constants.SEARCH_PARAM_ENDDT,tempEndDate.format(constants.DATE_ISO_FORMAT));
                console.log(`ConversationsSearchCriteria::: End Date is  ${tempEndDate.format(constants.DATE_ISO_FORMAT)}`);
            } 
        
            if(selectedDivisions){
                console.log(`Selected Divisions are ${JSON.stringify(selectedDivisions)}`)
                //searchCriteria.set('selectedDivisions', selectedDivisions)
                console.log(`Role is ${role}`);
                if (selectedDivisions.length === divisions.length || selectedDivisions.length === 0 ){ // all
                    console.log('All Divisions Selected');
                    if(role =='admin'){
                        searchCriteria.set(constants.SEARCH_PARAM_DIVISION, '*')
                    }else{
                        searchCriteria.set(constants.SEARCH_PARAM_DIVISION, decodeURIComponent(divisions.map(x => x.value)));
                    }
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_DIVISION, decodeURIComponent(selectedDivisions.map(x => x.value)));
                }
            }
            if (selectedQueues) {
                //searchCriteria.set('selectedQueues', selectedQueues)
                if (selectedQueues.length === queues.length || selectedQueues.length === 0){ // all
                    if(role =='admin'){
                        searchCriteria.set(constants.SEARCH_PARAM_QUEUES, '*')
                    }else{
                        searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(queues.map(x => x.value)));
                    }
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(selectedQueues.map(x => x.value)));
                }
            }
            
            if(selectedAgents){
                //searchCriteria.set('selectedAgents', selectedAgents);
                if (selectedAgents.length === agents.length) // all
                    searchCriteria.set(constants.SEARCH_PARAM_AGENT, '*');
                else
                    searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgents.map(x => x.value).join('|')));
            }

            if(selectedAgentIDs){
                //searchCriteria.set('selectedAgents', selectedAgents);
                if (selectedAgentIDs.length === agentID.length) // all
                    searchCriteria.set(constants.SEARCH_PARAM_AGENT_ID, '*');
                else
                    searchCriteria.set(constants.SEARCH_PARAM_AGENT_ID, decodeURIComponent(selectedAgentIDs.map(x => x.value)));
            }

            if(selectedConvDirection){
                //searchCriteria.set('selectedAgents', selectedAgents);
                if (selectedConvDirection.length === conv_direction.length) // all
                    searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, '*');
                else
                    searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, decodeURIComponent(selectedConvDirection.map(x => x.value)));
            }
            if (ani) searchCriteria.set(constants.SEARCH_PARAM_ANI, decodeURIComponent(ani));
            if (dnis) searchCriteria.set(constants.SEARCH_PARAM_DNIS, decodeURIComponent(dnis));
            if (conversationID) searchCriteria.set(constants.SEARCH_PARAM_CONVERSATIONID, conversationID);
            if (contactID) searchCriteria.set(constants.SEARCH_PARAM_CONTACT_ID, contactID);
            if (switchcallID) searchCriteria.set(constants.SEARCH_PARAM_SWITCHCALL_ID, switchcallID);
            if (originalContactID) searchCriteria.set(constants.SEARCH_PARAM_ORIGINALCONTACT_ID, originalContactID);
            props.search(utils.mapToObj(searchCriteria))
            appStorage.setItem(constants.R2S_SEARCH_CRITERIA, JSON.stringify(utils.mapToObj(searchCriteria)))
        } catch (exception) {
            console.log(`ConversationsSearchCriteria:search::exception`,exception);
            console.log(exception)
            
        }
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ANI</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={ani}
                            onChange={e => setANI(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">DNIS</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={dnis}
                            onChange={e => setDNIS(e.target.value)} />
                    </div>
                </div>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">SID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={conversationID}
                            onChange={e => setConversationID(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Switch Call ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={switchcallID}
                            onChange={e => setSwitchCallID(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Contact ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={contactID}
                            onChange={e => setContactID(e.target.value)} />
                    </div>
                </div>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Original Contact ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={originalContactID}
                            onChange={e => setOriginalContactID(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className='row justify-content-start'>
                {role !='client_user'?
                    <div className='col col-12 col-xl-3' >
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Division</span>
                            </div>
                            <MultiSelect className="multi-select division-multi-select"
                                options={divisions}
                                value={selectedDivisions}
                                onChange={e => { setSelectedDivisions(e) }}
                                labelledBy="Select"
                            />
                        </div>
                    </div>
                    :null}
                <div className='col col-12 col-xl-3'>
                <div className="input-group">
                    <div className="input-group-prepend">
                            <span className="input-group-text">Queues</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={queues}
                            value={selectedQueues}
                            onChange={e => { setSelectedQueues(e) }}
                            labelledBy="Select"
                        />
                        
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                <div className="input-group">
                    <div className="input-group-prepend">
                            <span className="input-group-text">Direction of the Call</span>
                        </div>
                        <MultiSelect className="multi-select direction-multi-select"
                            options={conv_direction}
                            value={selectedConvDirection}
                            onChange={e => { setSelectedConvDirection(e) }}
                            labelledBy="Select"
                        />
                        
                    </div>
                </div>

                </div>
            <div className='row justify-content-start'>
                
                    <div className='col col-12 col-xl-3'>
                        <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Agent ID</span>
                                </div>
                                <MultiSelect className="multi-select agents-multi-select"
                                    options={agentID}
                                    value={selectedAgentIDs}
                                    onChange={e => { setSelectedAgentIDs(e) }}
                                    labelledBy="Select"
                                />
                        </div>
                    </div>
        
                    <div className='col col-12 col-xl-3'>
                        <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Agent Name</span>
                                </div>
                                <MultiSelect className="multi-select agents-multi-select"
                                    options={agents}
                                    value={selectedAgents}
                                    onChange={e => { setSelectedAgents(e) }}
                                    labelledBy="Select"
                                />
                        </div>
                    </div>
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Interval *</span>
                            </div>

                            <RangePicker size="large" 
                            aria-required 
                            placement="bottomLeft" 
                            style={{borderRadius:"5px"}} 
                            format={constants.DISPLAY_DATE_FORMAT}
                            showTime={{
                                format: 'HH:mm',
                                defaultValue:[moment(reloaddateRange[0]).set({hour:0,minute:0,second:0,millisecond:0}),moment(reloaddateRange[0]).set({hour:23,minute:59,second:0,millisecond:0})]
                              }}
                              {...dynamicProps}
                            onChange={onSelectDate} />
                    </div>                   
                </div>

                
            </div>
            <br />
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )


}

export default ConversationsSearchCriteria