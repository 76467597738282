import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import AppRouter from './routers/AppRouter'
import { ToastProvider } from "./components/toastManager/ToastManager";

ReactDOM.render(
    <ToastProvider>
        <AppRouter />
    </ToastProvider>,
    document.getElementById('root')
)
registerServiceWorker()