import React from 'react'
import './Footer.css'
import { __release_version__ } from '../../__version__'

const Footer = () => {
    return (
        <footer className="footer" >
            <span>R2S Version {__release_version__} (CFS - legacy recordings - ap-southeast-2) </span>
        </footer>
    )
}

export default Footer