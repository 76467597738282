import React, { useState, useEffect } from 'react'
import './DownloadSearchCriteria.css'
import moment from 'moment';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const DownloadSearchCriteria = (props) => {
      
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    const recodingStatus = [
        { label: "Available", value: "Available" },
        { label: "Expired", value: "Expired" },
        { label: "In Progress", value: "In Progress" }
    ]
    

    function onSelectDate(dateArray, dateString) 
    {        
        console.log(dateArray)
        setdateRange(dateString);
    }

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
        console.log('searching...')
        let searchCriteria = {};
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0],'YYYY-MM-DD').set({"hour": 0, "minute": 0});           
            searchCriteria.startDate = tempStartDate.toISOString();
        } 
        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1],'YYYY-MM-DD').set({"hour": 23, "minute": 59});
            searchCriteria.endDate = tempEndDate.toISOString();
        }
        console.log(`DownloadSearchCriteria.js Search parameters are ${JSON.stringify(searchCriteria)}`);
        props.search(searchCriteria)
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    return (
        <div className="search-wrap pl-2">
            <div className='row justify-content-start'>                
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group d-inline-flex align-items-center w-auto">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval</span>
                        </div>
                        <RangePicker placement="bottomLeft" style={{borderRadius:"5px",padding:"7px"}} onChange={onSelectDate} />
                    </div>
                </div>
            </div>
            <br />
            <div className='row justify-content-start'>
                <div className='col-xs-2 pl-3 pr-2'>
                    <button type="button" className="btn btn-primary button-custom" onClick={search}>Search</button>
                </div>
                <div className='col-xs-2' >
                    <button type="button" className="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DownloadSearchCriteria