import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import './AlertModal.css'

const AlertModal = (props) => {
    const { isOpen, toggle, header, body } = props
    return (
        <Modal className="alert-modal animated fadeIn" isOpen={isOpen} toggle={toggle}>
            <ModalHeader className="alert-modal-header" toggle={toggle}><FontAwesomeIcon className="fa-1x" icon={faExclamationCircle} /> {header}</ModalHeader>
            <ModalBody>{body}</ModalBody>
        </Modal>
    )
}

export default AlertModal
